import React from "react";
import { Link } from "gatsby-plugin-intl";
import { Heading, Box } from "@chakra-ui/core";
import PlaceType from "./placeType";
import MaclicImage from "../images/maclicImage";
import CategoryList from "../categories/categoryList";
import { useIntl } from "gatsby-plugin-intl";
import { getAttributeTranslated } from "../../util/entities";

const getPhoto = (place) => {
  if (place.photos && place.photos.length > 0) {
    return place.photos[0].reference;
  }
  return null;
}

const PlacePreview = ({ place, ...rest }) => {
  const intl = useIntl();
  const link = place.fields ? place.fields.slug : "#";
  return (
    <Box>
      <Box shadow="md" borderWidth="1px" {...rest}>
        <Link to={link}>
          <MaclicImage size="previewThird" src={getPhoto(place)}/>
        </Link>
        <Box
          w="100%"
          // pos="absolute"
          // bottom="0"
          // left="0"
          py={2}
          px={4}
        >
          <Link to={link}>
            <Heading as="h2" size="xs" fontWeight={600}>
              {getAttributeTranslated(place, 'name', intl.locale)}
            </Heading>
          </Link>
          <CategoryList listing categories={place.categories} delivery={place.delivery} />
        </Box>

      </Box>
    </Box>
  );
}

PlacePreview.propTypes = {
  place: PlaceType.isRequired,
};

export default PlacePreview;
