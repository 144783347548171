import React from "react";
import MainLayout from "../components/layout/main";
import { graphql } from "gatsby";
import { Box, Flex } from "@chakra-ui/core";
import { SecondaryHeading } from "../components/headings";
import MarkDown from "../components/MarkDown";
import SEO from "../components/SEO";
import PlacePreview from "../components/places/placePreview";
import PlaceCarouselWrapper from "../components/places/placeCarousel";
import { useIntl } from "gatsby-plugin-intl";
import { getAttributeTranslated } from "../util/entities"
import { useRegisterScreen } from "../util/firebaseFunctions"

const getStory = (data) => {
  const { story, place } = data;
  if (story) {
    return story;
  }
  if (place) {
    const result = {
      title: place.story_title,
      title_en: place.story_title_en,
      details: place.story,
      details_en: place.story_en,
      category: place.name,
      category_en: place.name_en,
      fields: {
        slug: place.fields.slugStory,
      },
    };
    if (place.story_photo) {
      result.photo = place.story_photo;
    } else if (place.photos && place.photos.length > 0) {
      result.photo = place.photos[0].reference;
    }
    return result;
  }
  return null;
}

export default ({ data }) => {
  const intl = useIntl();
  const story = getStory(data);
  const { place } = data;
  if (story) {
    useRegisterScreen(story.title);
    const title = getAttributeTranslated(story, 'title', intl.locale);
    let fullTitle = title;
    const by = intl.formatMessage({ id: "util.by" });
    !!place &&
      (fullTitle = `${title} ${by} ${getAttributeTranslated(place, 'name', intl.locale)}`);
    const details = getAttributeTranslated(story, 'details', intl.locale);
    return (
      <MainLayout>
        <SEO
          description={details}
          image={story.photo}
          pathname={story.fields.slug}
          title={fullTitle}
        />
        <Box maxWidth={750} ml="auto" mr="auto">
          <PlaceCarouselWrapper
            photos={[story.photo]}
            mt={-4}
            ml={[-4, -4, 0, 0]}
            mr={[-4, -4, 0, 0]}
          />
        </Box>
        <Flex justify="center" align="center">
          <Box
            width="100%"
            maxWidth={[
              "95%", // base
              "400px", // 480px upwards
              "600px", // 768px upwards
              "600px", // 992px upwards
            ]}
          >
            <SecondaryHeading>
              {title}
            </SecondaryHeading>
            <MarkDown md={details} />
          </Box>
        </Flex>
        {!!place && (
          <Box mt={8} maxWidth={300} mx="auto">
            <PlacePreview key={place.id} place={place} />
          </Box>
        )}
      </MainLayout>
    );
  }
  return null;
};

export const query = graphql`
  query($id: String!) {
    story(id: {eq: $id}) {
      title
      title_en
      category
      category_en
      photo
      details
      details_en
      fields {
        slug
      }
    }
    place(id: {eq: $id}) {
      story_photo
      story_title
      story_title_en
      story
      story_en
      name
      name_en
      id
      photos {
        reference
      }
      fields {
        slug
        slugStory
      }
      categories {
        name
        name_en
        id
        fields {
          slug
        }
      }
    }
  }
`;
