import * as PropTypes from 'prop-types';

const PlaceType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(PropTypes.shape({
    reference: PropTypes.string,
  })),
});

export default PlaceType;
